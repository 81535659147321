import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import './index.css'
import App from './App'; // Your App component

ReactDOM.render(
  <Router> {/* Wrap your entire app with Router here */}
    <App />
  </Router>,
  document.getElementById('root')
);
