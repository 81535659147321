import React from 'react';
import { Box, Drawer, IconButton, Divider, Typography } from '@mui/material';
import COLORS from '../../constants/colors'; // Import colors
import FONTS from '../../constants/fonts'; // Import fonts
import logo from '../../assets/logo_main.svg'; // Import the logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ModernDrawer = ({ mode, drawerOpen, toggleDrawer }) => {
  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          margin: '16px',
          height: 'auto', // Automatically adjust height to fit content
          maxHeight: '95vh', // Ensure it doesn't exceed the viewport height
          borderRadius: '16px',
          overflowY: 'auto',
          backgroundColor: mode === 'light' 
        ? 'rgba(255, 255, 255, 0.8)' // Light mode background
        : 'rgba(0, 0, 0, 0.8)', // Dark mode background

          backdropFilter: 'blur(50px)', // Blur effect for glassmorphism
        },
      }}
    >
      <Box
        sx={{
          width: 260,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centers the content horizontally
          justifyContent: 'center', // Centers content vertically
          padding: '22px',
          backgroundColor: 'transparent', // Transparent to show the glass effect
        }}
      >
        {/* Logo and Close Button     
        */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <IconButton
            onClick={toggleDrawer}
            sx={{
              alignSelf: 'flex-end',
              backgroundColor: mode === 'light' ? COLORS.lightGray : COLORS.darkBackground,
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: mode === 'light' ? COLORS.primary : COLORS.white,
                fontSize: '10px',
                backgroundColor: mode === 'light' ? 'rgba(0, 0, 0, .1)' : 'rgba(255, 255, 255, .07)',
                padding: '8px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                marginRight: '-80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </IconButton>
          <img
            src={logo}
            alt="Logo"
            style={{ height: '38px', marginBottom: '10px', width: 'auto' }}
          />
          
        </Box>

       

        {/* Menu Items */}
        <a href="/" style={menuLinkStyle(mode)}>
          Home
        </a>
        <a href="/services" style={menuLinkStyle(mode)}>
          Our services
        </a>
        <a href="/projects" style={menuLinkStyle(mode)}>
          Projects
        </a>
        <a href="/aboutus" style={menuLinkStyle(mode)}>
          About us
        </a>

       

        <Box sx={{ marginTop: '20px', width: '100%' }}>
          <a
            href="#contact"
            style={{
              ...menuLinkStyle(mode),
              color: COLORS.white,
              backgroundColor: COLORS.primary,
              textDecoration: 'none',
              padding: '10px 20px',
              borderRadius: '20px',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            Call Us
          </a>
        </Box>
      </Box>
    </Drawer>
  );
};

const menuLinkStyle = (mode) => ({
  display: 'block',
  color: mode === 'light' ? COLORS.primary : COLORS.white,
  textTransform: 'none',
  marginBottom: '8px',
  padding: '10px 20px',
  borderRadius: '8px',
  fontFamily: FONTS.poppins,
  textDecoration: 'none',
  width: '100%',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: mode === 'light' ? COLORS.lightGray : COLORS.primary,
    color: mode === 'light' ? COLORS.primary : COLORS.white,
  },
});

export default ModernDrawer;
