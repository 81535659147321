// src/constants/colors.js
// Define colors for light and dark mode
const COLORS = {
  primary: '#D4652E', // Primary color
  secondary: '#575454', // Lighter color
  text: '#FFFFFFB3', // Lighter text color for dark mode (modern)
  white:'#ffffff',
  border_dark: '#BEBEBE33'
};


export default COLORS;
