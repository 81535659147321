// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom'; // Import only Routes and Route
import Home from './pages/Home'; // Import the Home component
import Aboutus from './pages/About'; // Import the Aboutus component
import Projects from './pages/Projects';
import Services from './pages/Services';

function App() {
  return (
    <Routes> {/* Define all routes here */}
      <Route path="/" element={<Home />} /> {/* Default route for Home */}
      <Route path="/aboutus" element={<Aboutus />} /> {/* Route for About Us */}
      <Route path="/projects" element={<Projects />} /> {/* Route for About Us */}
      <Route path="/services" element={<Services />} /> {/* Route for About Us */}


    </Routes>
  );
}

export default App;
