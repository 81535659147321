import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Divider, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import COLORS from '../../constants/colors'; // Replace with your defined color constants
import image from '../../assets/images/project.png'; // Your image path
import ukara_image from '../../assets/images/ukara_image.png'
import delvety_image from '../../assets/images/delvety.png'


// Sample projects data (6 projects for 3x2 grid)
const projectsData = [
  {
    name: 'Ukaramental Health Website',
    description:
      'An online platform designed to provide mental health resources, counseling services, and community support.',
    link: 'https://ukaramentalhealth.org', 
    imageSrc: ukara_image,
    largeCard: true,
  },
  {
    name: 'Delvety website',
    description:
      'A nice software enginnering website with a mordern touch and feel. Sleek, interractive designs',
    link: 'https://delvitsolutions.com/',
    imageSrc: delvety_image,
    largeCard: true,
  },
  
];

function ProjectCard({ imageSrc, projectName, description, link, largeCard = false }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row-reverse' }, // Column for small screens
        alignItems: 'center',
        padding: 1,
        borderRadius: '12px',
        boxShadow: isDarkMode ? 'none' : 'none',
        border: isDarkMode ? `1px solid ${COLORS.border_dark}` : 'none',
        backgroundColor: isDarkMode ? 'transparent' : 'white',
        height: '100%',
        width: '100%',
        transition: 'transform 0.3s, box-shadow 0.3s',
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopRightRadius: { xs: '12px', sm: '0' },
          borderBottomRightRadius: { xs: '0', sm: '12px' },
          width: { xs: '100%', sm: 'auto' }, // Full width on small screens
          maxWidth: '100%', // Ensure image doesn't overflow
        }}
      >
        <img
          src={imageSrc}
          alt={projectName}
          style={{
            width: '100%',
            height: 'auto',
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
            transition: 'transform 0.3s',
          }}
        />
      </Box>

      {/* Text Content Section */}
      <CardContent
        sx={{
          flex: 1,
          padding: { xs: 2, sm: 4 }, // Adjust padding for small screens
          textAlign: { xs: 'center', sm: 'left' }, // Center align text on small screens
        }}
      >
        <Typography
          variant={largeCard ? 'h4' : 'h5'}
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            fontSize: { xs: '1.1rem', md: '1.2rem' },
            color: isDarkMode ? COLORS.white : COLORS.primary,
            marginBottom: 1,
          }}
        >
          {projectName}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: { xs: '0.8rem', md: '.9rem' },
            color: isDarkMode
              ? 'rgba(255, 255, 255, 0.7)'
              : 'rgba(0, 0, 0, 0.8)',
            lineHeight: 1.5,
          }}
        >
          {description}
        </Typography>
        <Box>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              sx={{
                backgroundColor: COLORS.primary,
                color: 'white',
                fontSize: { xs: '0.7rem', sm: '.8rem' },
                padding: '6px 16px',
                borderRadius: '50px',
                marginTop: '20px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: COLORS.primaryDark,
                  transform: 'scale(1.05)',
                },
                '&:active': {
                  transform: 'scale(1)',
                },
              }}
            >
              {largeCard ? 'View Project' : 'View Project'}
            </Button>
          </a>
        </Box>
      </CardContent>
    </Card>
  );
}

function Projects() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      id="projects"
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        py: 8,
        px: 0, // Remove horizontal padding
        margin: 0, // Ensure no margin is applied
      }}
    >
      {/* Section Heading */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' },
          textAlign: 'center',
          marginBottom: 4,
          color: isDarkMode ? '#FFFFFF' : COLORS.primary,
        }}
      >
        Our Projects
      </Typography>

      {/* Horizontal Divider */}
      <Divider
        sx={{
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary,
          width: '10%',
          margin: { xs: '0 auto', sm: '0 auto' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      />

      {/* Projects Grid - 3x2 layout */}
      <Grid
        container
        sx={{
          justifyContent: 'center',
          paddingLeft: '16px',
          paddingRight: '16px',
          maxWidth: '2000px', // Increased width for the card container
          margin: '0 auto',
          overflowX: 'hidden',
        }}
      >
        {projectsData.map((project, index) => (
          <Grid
            item
            xs={12}
            sm={6} // 2 columns on small screens
            md={4}  // 3 columns on medium and larger screens
            key={index}
            sx={{
              margin: { xs: '8px 0', sm: '12px' },
            }}
          >
            {/* Project Card */}
            <ProjectCard
              projectName={project.name}
              description={project.description}
              link={project.link}
              imageSrc={project.imageSrc}
              largeCard={project.largeCard}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Projects;
