import React from 'react';
import { Box, Typography, Grid, TextField, Button, useTheme, Divider } from '@mui/material';
import { ReactComponent as ContactSvg } from '../../assets/contactus.svg'; // Replace with your SVG path
import COLORS from '../../constants/colors';

const ContactUs = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    
    <Box
    id = "contact"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        padding: 4,
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: '1200px',
          alignItems: 'center',
        }}
      >
        {/* Left Side: SVG Graphics */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
            }}
          >
            <ContactSvg
              style={{
                width: '100%',
                height: 'auto',
                fill: isDarkMode ? '#FFFFFF' : '#000000',
              }}
            />
          </Box>
        </Grid>

        {/* Right Side: Contact Form */}
        <Grid item xs={12} md={6}>
          {/* Section Heading */}
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 600,
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' },
              textAlign: 'left',
              marginBottom: 4,
              color: isDarkMode ? '#FFFFFF' : COLORS.primary,
            }}
          >
            Contact Us
          </Typography>

          {/* Horizontal Divider */}
          <Divider
            sx={{
              borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary,
              width: '10%',
              marginBottom: { xs: 3, sm: 4 },
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              marginBottom: 4,
              color: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.7)',
            }}
          >
            We’d love to hear from you! Send us an email by clicking the button below, and we’ll get back to you as soon as possible.
          </Typography>

          {/* Contact Form */}
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            

            <a href="mailto:info@delvitsolutions.com" style={{ textDecoration: 'none' }}>
  <Button
    variant="contained"
    size="large"
    sx={{
      display: { xs: 'flex', sm: 'flex' },
      color: COLORS.white,
      backgroundColor: COLORS.primary,
      textTransform: 'none',
      fontWeight: '500',
      borderRadius: '20px',
      px: 3,
      '&:hover': {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
      },
    }}
  >
    Send email
  </Button>
</a>

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;