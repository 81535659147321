import { createTheme } from '@mui/material/styles';

// Define colors for light and dark mode
const COLORS = {
  primary: '#346080', // Primary color
  secondary: '#575454', // Lighter color
  backgroundLight: '#f4f4f4', // Light background color
  backgroundDark: '#2C2C2C', // Softer dark background for modern look
  textLight: '#333333', // Light text color
  textDark: '#e0e0e0', // Lighter text color for dark mode (modern)
  buttonDark: '#4CAF50', // Greenish button color in dark mode
};

// Create the theme with support for light and dark mode
const createCustomTheme = (mode = 'dark') => {
  return createTheme({
    palette: {
      mode: mode, // Defaults to "dark" mode
      primary: {
        main: COLORS.primary,
      },
      secondary: {
        main: COLORS.secondary,
      },
      background: {
        default: mode === 'light' ? COLORS.backgroundLight : COLORS.backgroundDark,
      },
      text: {
        primary: mode === 'light' ? COLORS.textLight : COLORS.textDark,
      },
      action: {
        active: mode === 'light' ? COLORS.primary : COLORS.buttonDark, // Button active state
      },
    },
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });
};

export default createCustomTheme;
