import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faBullseye, faFlag } from '@fortawesome/free-solid-svg-icons'; // Adjusted icons
import { useTheme } from '@mui/material/styles';
import COLORS from '../../constants/colors'; // Replace with your defined color constants

// Updated sample data for mission, vision, and core values
const services = [
  {
    icon: faBullseye,
    title: 'Mission',
    description: 'Our mission is to provide innovative solutions that drive progress and deliver value to our clients.',
  },
  {
    icon: faFlag,
    title: 'Vision',
    description: 'Our vision is to become a leading technology company known for transformative and sustainable solutions.',
  },
  {
    icon: faLightbulb,
    title: 'Core Values',
    description: 'We value integrity, innovation, collaboration, and a commitment to excellence in all our work.',
  },
];

function Services() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        py: 8,
        px: 0, // Remove horizontal padding
        margin: 0, // Ensure no margin is applied
      }}
    >
      {/* Section Heading */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' },
          textAlign: 'center',
          marginBottom: 4,
          color: isDarkMode ? '#FFFFFF' : COLORS.primary,
        }}
      >
        Our Values
      </Typography>

      {/* Horizontal Divider */}
      <Divider
        sx={{
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary, // Color based on theme
          width: '10%', // Width of the divider
          margin: { xs: '0 auto', sm: '0 auto' }, // Centers on small screens, not on larger screens
          marginBottom: { xs: 3, sm: 4 }, // Spacing below the divider
        }}
      />

      {/* Services Grid */}
      <Grid
        container
        sx={{
          justifyContent: 'center', // Centers the items horizontally
          paddingLeft: '16px', // Add a small left padding to avoid unwanted edge contact
          paddingRight: '16px', // Add a small right padding
          maxWidth: '1500px',
          margin: '0 auto',
          overflowX: 'hidden', // Prevent horizontal scrolling
        }}
      >
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={4} // This makes it 3 columns on medium and larger screens
            lg={3} // Ensure it stays 3x1 grid on large screens
            key={index}
            sx={{
              // Apply margin for spacing between cards
              margin: { xs: '8px 0', sm: '12px' }, // Add vertical margin on small screens, horizontal margin on larger screens
            }}
          >
            {/* Service Card */}
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 2,
                borderRadius: '12px',
                boxShadow: isDarkMode
                  ? 'none'
                  : 'none',
                border: isDarkMode
                  ? `1px solid ${COLORS.border_dark}`
                  : 'none',
                backgroundColor: isDarkMode
                  ? 'transparent'
                  : 'white',
                height: '100%',
                width: '100%', // Ensure the card takes full width
                transition: 'transform 0.3s, box-shadow 0.3s',
              }}
            >
              {/* Icon Column */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 2,
                  ml: 2,
                  backgroundColor: isDarkMode
                    ? COLORS.primaryDark
                    : COLORS.primaryLight,
                }}
              >
                <FontAwesomeIcon
                  icon={service.icon}
                  style={{ fontSize: '40px', color: COLORS.primary }}
                />
              </Box>

              {/* Text Column */}
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: { xs: '1.1rem', md: '1.2rem' },
                    color: isDarkMode ? COLORS.white : COLORS.primary,
                    marginBottom: 1,
                  }}
                >
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: { xs: '0.8rem', md: '.9rem' },
                    color: isDarkMode
                      ? 'rgba(255, 255, 255, 0.7)'
                      : 'rgba(0, 0, 0, 0.8)',
                    lineHeight: 1.5,
                  }}
                >
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Services;
