import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faChartLine, faTasks, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import COLORS from '../../constants/colors'; // Replace with your defined color constants

// Sample data for statistics
const statistics = [
  {
    icon: faUsers,
    title: 'Clients',
    number: '20+',
  },
  {
    icon: faChartLine,
    title: 'Projects Completed',
    number: '30+',
  },
  {
    icon: faTasks,
    title: 'Tasks Done',
    number: '30+',
  },
 
];

function Statistics() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        py: 8,
        px: 0, // Remove horizontal padding
        margin: 0, // Ensure no margin is applied
      }}
    >
      {/* Section Heading */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          fontSize: { xs: '1.2rem', sm: '1.6rem', md: '1.8rem' }, // Adjusted font size for responsiveness
          textAlign: 'center',
          marginBottom: 4,
          color: isDarkMode ? '#FFFFFF' : COLORS.primary,
        }}
      >
        Achievements
      </Typography>

      {/* Horizontal Divider */}
      <Divider
        sx={{
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary, // Color based on theme
          width: '10%', // Width of the divider
          margin: { xs: '0 auto', sm: '0 auto' }, // Centers on small screens, not on larger screens
          marginBottom: { xs: 3, sm: 4 }, // Spacing below the divider
        }}
      />

      {/* Statistics Grid (Horizontal Row Layout) */}
      <Grid
        container
        direction="row"
        justifyContent="center" // Center the cards horizontally
        alignItems="center"
        sx={{
          paddingLeft: '16px', // Add a small left padding to avoid unwanted edge contact
          paddingRight: '16px', // Add a small right padding
          maxWidth: '1500px',
          margin: '0 auto',
          overflowX: 'auto', // Allows horizontal scrolling if needed on smaller screens
        }}
      >
        {statistics.map((stat, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3} // 1 column on small screens, 2 on medium, and 4 on large screens
            key={index}
            sx={{
              marginBottom: 3, // Margin at the bottom to separate cards
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              paddingLeft: { xs: 1, sm: 2 }, // Adjusts padding for smaller screens
              paddingRight: { xs: 1, sm: 2 }, // Adjusts padding for smaller screens
            }}
          >
            {/* Statistic Card */}
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
                borderRadius: '12px',
                boxShadow: isDarkMode ? 'none' : 'none',
                border: isDarkMode
                  ? `1px solid ${COLORS.border_dark}`
                  : 'none',
                backgroundColor: isDarkMode
                  ? 'transparent'
                  : 'white',
                height: '100%',
                width: '100%', // Ensure the card takes full width
                textAlign: 'center',
                transition: 'transform 0.3s, box-shadow 0.3s',
                
              }}
            >
              {/* Icon Column */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: isDarkMode
                    ? COLORS.primaryDark
                    : COLORS.primaryLight,
                  p: 0,
                  borderRadius: '50%',
                  marginBottom: 1,
                }}
              >
                <FontAwesomeIcon
                  icon={stat.icon}
                  style={{ fontSize: '40px', color: COLORS.primary }}
                />
              </Box>

              {/* Text Column */}
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 700,
                    fontSize: { xs: '.9rem', sm: '1rem', md: '1.1rem' }, // Reduced font size for text
                    color: isDarkMode ? COLORS.white : COLORS.primary,
                    marginBottom: 1,
                  }}
                >
                  {stat.title}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: { xs: '1.3rem', sm: '1.6rem', md: '1.8rem' }, // Reduced font size for numbers
                    color: isDarkMode
                      ? 'rgba(255, 255, 255, 0.7)'
                      : 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  {stat.number}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Statistics;
