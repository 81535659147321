import React from 'react';
import logo from '../../assets/favicon.svg'; 

import COLORS from '../../constants/colors';

const Preloader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#2C2C2C', // Dark theme background
        zIndex: 9999,
        flexDirection: 'column', // Stack logo and progress bar vertically
      }}
    >
      <img
        src={logo}
        alt="Loading..."
        style={{
          height: '40px', // Size of the logo
          width: 'auto',
          marginBottom: '10px', // Add space between logo and progress bar
        }}
      />

      {/* Progress Bar */}
      <div
        style={{
          width: '80px', // Width of the progress bar
          height: '4px', // Height of the progress bar
          backgroundColor: '#333333', // Darker background for the progress bar track
          borderRadius: '2px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '50%', // Adjust this value dynamically if needed
            height: '100%',
            backgroundColor: COLORS.primary, // Color of the progress indicator
            animation: 'progressBar 2s ease-in-out infinite', // Animated progress
          }}
        />
      </div>

      {/* Add the CSS for the progress bar animation */}
      <style>
        {`
          @keyframes progressBar {
            0% {
              width: 0%;
            }
            50% {
              width: 80%;
            }
            100% {
              width: 0%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Preloader;
