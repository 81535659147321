import React from 'react';
import { Box, Grid, Typography, Button, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import heroImage from '../../assets/svg/about.svg'; // Replace with your image path
import COLORS from '../../constants/colors'; // Replace with your color constants

function ComingSoonHero() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        paddingY: 4,
        pt: 6,
        paddingX: { xs: 2, sm: 4, md: 8 },
      }}
    >
      

      {/* Glassmorphism Background Effect */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: '85%',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(100px)', // Glassmorphism blur effect
          borderRadius: '20px',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          zIndex: -1,
        }}
      />

   

      <Grid container spacing={2} sx={{ maxWidth: '1500px' }}>
        {/* Left Column */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: { xs: 5, sm: 0 }, // Add margin at bottom on small screens
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 600,
              fontSize: { xs: '2.2rem', md: '3rem' }, // Adjusted font sizes for responsiveness
              lineHeight: { xs: 1.2, sm: 1.3, md: 1.4 }, // Optional: Adjust line height for better readability on small screens
              color: isDarkMode ? '#FFFFFF' : COLORS.primary,
              marginBottom: { xs: 2, sm: 3, md: 3 }, // Adjusted margin bottom for smaller screens
              textAlign: { xs: 'center', sm: 'left' }, // Centers text on small screens, aligns left on larger screens
            }}
          >
            About Us
          </Typography>

          {/* Horizontal Divider */}
          <Divider
            sx={{
              borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary, // Color based on theme
              width: '40%', // Width of the divider
              margin: { xs: '0 auto', sm: '0' }, // Centers on small screens, not on larger screens
              marginBottom: { xs: 3, sm: 4 }, // Spacing below the divider
            }}
          />

          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              color: isDarkMode ? COLORS.text : 'rgba(0, 0, 0, 0.8)',
              fontSize: { xs: '.8rem', sm: '1rem', md: '1rem' }, // Adjust font size for responsiveness
              lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Optional: Adjust line height for better readability
              marginBottom: { xs: 3, sm: 4, md: 4 }, // Adjust bottom margin for better spacing
              textAlign: { xs: 'center', sm: 'left' }, // Center the text on small screens and left-align it on larger screens
            }}
          >
             Founded with a passion for innovation and problem-solving, Delvit Solutions has grown into a trusted software development agency. We specialize 
            in creating custom software solutions that drive business growth and 
            streamline operations. From startups to established enterprises, we pride 
            ourselves on delivering exceptional results that exceed expectations
          </Typography>

          
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box
              component="img"
              src={heroImage}
              alt="Hero"
              sx={{
                width: { xs: '100%', md: '85%' },
                height: 'auto',
                borderRadius: '8px',
                animation: 'float 4s ease-in-out infinite',
                zIndex: 1,
              }}
            />
          </Box>
          <style>
            {`
              @keyframes float {
                0%, 100% {
                  transform: translateY(0px);
                }
                50% {
                  transform: translateY(-10px);
                }
              }
            `}
          </style>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ComingSoonHero;
