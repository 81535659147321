import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { FaReact, FaNodeJs, FaDatabase, FaAngular, FaVuejs, FaCss3Alt, FaJsSquare, FaMobileAlt, FaSwift, FaAndroid } from 'react-icons/fa';
import { SiTailwindcss, SiFlutter, SiDjango, SiRuby, SiSpring, SiMongodb } from 'react-icons/si';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion'; // For animations
import COLORS from '../../constants/colors'; // Replace with your defined color constants

// Sample data for tech stack
const techStack = [
  // Web Development
  { icon: <FaReact />, name: 'React' },
  { icon: <FaAngular />, name: 'Angular' },
  { icon: <FaVuejs />, name: 'Vue.js' },
  { icon: <SiTailwindcss />, name: 'Tailwind CSS' },
  { icon: <FaCss3Alt />, name: 'CSS3' },
  { icon: <FaJsSquare />, name: 'JavaScript' },

  // Mobile Development
  { icon: <FaMobileAlt />, name: 'React Native' },
  { icon: <SiFlutter />, name: 'Flutter' },
  { icon: <FaSwift />, name: 'Swift' },
  { icon: <FaAndroid />, name: 'Kotlin' },

  // Backend and Server-Side Technologies
  { icon: <FaNodeJs />, name: 'Node.js' },
  { icon: <SiDjango />, name: 'Django' },
  { icon: <SiRuby />, name: 'Ruby on Rails' },
  { icon: <SiSpring />, name: 'Spring Boot' },
  { icon: <SiMongodb />, name: 'MongoDB' },
  { icon: <FaDatabase />, name: 'MySQL' },
];

function TechStack() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        py: 8,
        px: 0,
        margin: 0,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          fontSize: { xs: '1.2rem', sm: '1.6rem', md: '1.8rem' },
          textAlign: 'center',
          marginBottom: 4,
          color: isDarkMode ? '#FFFFFF' : COLORS.primary,
        }}
      >
        Tech Stack
      </Typography>

      <Divider
        sx={{
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary,
          width: '10%',
          margin: { xs: '0 auto', sm: '0 auto' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      />

      {/* Scrolling Icons */}
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          position: 'relative',
          maxWidth: '60%', // Reduce the width of the scrolling box
          margin: '0 auto',
          maskImage: 'linear-gradient(to right, transparent, black 15%, black 85%, transparent)', // Fade in/out effect
          WebkitMaskImage: 'linear-gradient(to right, transparent, black 15%, black 85%, transparent)', // For Safari
        }}
      >
        <motion.div
          style={{ display: 'flex', gap: '24px', whiteSpace: 'nowrap' }}
          animate={{
            x: ['70%', '-70%'], // Move icons from right to left
          }}
          transition={{
            repeat: Infinity,
            duration: 30, // Controls the speed of scrolling
            ease: 'linear',
          }}
        >
          {techStack.map((tech, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: isDarkMode ? COLORS.primaryDark : COLORS.primaryLight,
                p: 2,
                borderRadius: '50%',
                width: '70px',
                height: '70px',
              }}
            >
              {React.cloneElement(tech.icon, {
                style: { fontSize: '36px', color: COLORS.primary },
              })}
            </Box>
          ))}
        </motion.div>
      </Box>
    </Box>
  );
}

export default TechStack;
