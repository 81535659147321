import React, { useState } from 'react';
import { Box, Typography, Button, Drawer, IconButton, Link } from '@mui/material';
import COLORS from '../../constants/colors'; // Import colors
import FONTS from '../../constants/fonts'; // Import fonts
import logo from '../../assets/logo_main.svg'; // Import the logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faSun, faMoon, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; // Import the icons
import SideNav from './Sidenav';
import Bars_icon_light from '../../assets/icons/sidenav_light.svg'; // Adjust the path to your SVG file
import Bars_icon_dark from '../../assets/icons/sidenav_dark.svg'; // Adjust the path to your SVG file


const Header = ({ mode, onToggleMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false); // Manage side nav drawer state

  const handleModeToggle = (newMode) => {
    onToggleMode(newMode);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const headerLinkStyle = {
    color: COLORS.primary, // Change link color based on mode
    fontFamily: FONTS.primary,
    fontSize: '16px',
    border: 'none',
    textDecoration: 'none',
    margin: '0 30px',
    textTransform: 'none',
    fontWeight: '500', // Slightly bolder text
    position: 'relative', // For creating custom underline effect
    transition: 'all 0.3s ease-in-out', // Smooth transition for all properties
    '&:hover': {
      color: mode === 'light' ? COLORS.primary : COLORS.white, // Hover color based on mode
      transform: 'scale(1)', // Slightly scale up on hover
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '4px',
      backgroundColor: COLORS.primary, // Underline color based on mode
      bottom: '-6px', // Adjust the distance of the underline (negative value for margin-like spacing)
      left: '0',
      transform: 'scaleX(0)',
      transformOrigin: 'bottom right',
      transition: 'transform 0.5s ease-out',
    },
    '&:hover:after': {
      transform: 'scaleX(1)', // Underline effect on hover
      transformOrigin: 'bottom left',
    },
  };
  

  const iconStyle = {
    color: mode === 'light' ? COLORS.primary : COLORS.white, // Icon color based on mode
    marginRight: '20px',
    cursor: 'pointer',
    fontSize: '20px',
    transition: 'transform 0.3s ease-in-out', // Add smooth transition
    '&:hover': {
      transform: 'scale(1.1)', // Slightly scale up on hover
    },
  };

  return (
    <>
      {/* Header */}
      <Box
        sx={{
          backgroundColor: mode === 'light' 
          ? 'rgb(255, 255, 255)' // Light mode background
          : 'rgb(44, 44, 44)', // Dark mode background
        backdropFilter: 'blur(20px)', // Apply the blur effect
        WebkitBackdropFilter: 'blur(10px)', // For Safari support
        color: 'text.primary',
        fontFamily: FONTS.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 20px',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        paddingY: 4,
        pt: 3,
        paddingX: { xs: 2, sm: 4, md: 8 },
        flexDirection: { xs: 'row', sm: 'row' }, // Ensure inline layout on small screens
        }}
      >
        {/* Left Section: Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={logo}
          alt="Logo"
          style={{
            height: '35px', // Logo size
            marginRight: '20px',
            width: 'auto', // Keep the aspect ratio intact
          }}
        />
        </Box>

        {/* Center Section: Navigation Links */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', lg: 'flex' },
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginBottom: { xs: '0', sm: '0' },
            paddingTop: { xs: '10px', sm: '0' }, // Add padding top on small screens to prevent crowding
          }}
        >
          <Link sx={headerLinkStyle} href="/">Home</Link>
          <Link sx={headerLinkStyle} href="/services">Our services</Link>
          <Link sx={headerLinkStyle} href="/projects">Projects</Link>
          <Link sx={headerLinkStyle} href="/aboutus">About Us</Link>

        </Box>

        {/* Right Section: Theme Toggle and Hamburger Icon */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Theme Toggle: Sun for light, Moon for dark */}
          {mode === 'light' ? (
            <FontAwesomeIcon
              icon={faSun}
              style={iconStyle}
              onClick={() => handleModeToggle('dark')}
            />
          ) : (
            <FontAwesomeIcon
              icon={faMoon}
              style={iconStyle}
              onClick={() => handleModeToggle('light')}
            />
          )}

          

            <Button
              component="a" // Use anchor tag
              href="#contact" // URL to login page
              sx={{
                display: { xs: 'none', sm: 'flex' },
                color: COLORS.white,
                backgroundColor: COLORS.primary,
                fontFamily: FONTS.primary,
                textTransform: 'none',
                marginLeft: '10px',
                marginRight: '10px',
                fontWeight: '500',
                borderRadius: '20px', // Increased border radius
                px: 3, // Add padding to left and right (adjust as needed)
                '&:hover': {
                  backgroundColor: COLORS.primary,
                  color: COLORS.white,
                },
              }}
            >
              Contact us
            </Button>





                  {/* Hamburger Menu on Small Screens */}
            <IconButton
              sx={{
                display: { xs: 'block', sm: 'block', lg: 'none' },
                marginLeft: '10px',
              }}
              onClick={toggleDrawer}
            >
              <img
                src={mode === 'light' ? Bars_icon_light : Bars_icon_dark} // Dynamically choose the icon
                alt="Menu Icon"
                style={{
                  width: '24px',
                  height: '24px',
                }}
              />
            </IconButton>



        </Box>
      </Box>

      {/* Side Navigation (Drawer) for Mobile */}
      <SideNav drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} mode={mode} />
    </>
  );
};

export default Header;
