import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { motion } from 'framer-motion'; // Import Framer Motion
import createCustomTheme from '../constants/theme'; // Adjust import path if necessary
import Header from './Reusable/Header';
import Hero from './aboutus/Hero';
import Preloader from './aboutus/Preloader'; // Import the Preloader component
import Services from './aboutus/Services';
import Footer from './Reusable/Footer';
import Team from './aboutus/Team';

const Aboutus = () => {
  const [mode, setMode] = useState('dark'); // State to manage light/dark mode
  const [loading, setLoading] = useState(true); // State to manage the preloader

  // Function to handle theme mode toggle
  const handleThemeToggle = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light')); // Toggle mode
  };

  // Simulate a loading phase (e.g., fetching data)
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000); // Simulated 2-second loading
    return () => clearTimeout(timer);
  }, []);

  // Generate the theme based on the mode
  const theme = createCustomTheme(mode);

  // Animation Variants
  const fadeIn = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const slideInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  const slideInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  // Render the Preloader during the loading phase
  if (loading) {
    return <Preloader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Ensures consistent baseline styles */}

      {/* Header */}
      <motion.div 
        initial="hidden" 
        whileInView="visible" 
        variants={fadeIn} 
        viewport={{ once: true, amount: 0.5 }} // Trigger animation when 50% of the component is in view
      >
        <Header mode={mode} onToggleMode={handleThemeToggle} /> {/* Pass mode and handler */}
      </motion.div>

      {/* Hero */}
      <motion.div 
        initial="hidden" 
        whileInView="visible" 
        variants={slideInLeft} 
        viewport={{ once: true, amount: 0.5 }}
      >
        <Hero />
      </motion.div>

     
        <Services />

    
        <Team />

      
        <Footer />
    </ThemeProvider>
  );
};

export default Aboutus;
