import React from 'react';
import { Box, Typography, Grid, Card, CardContent,Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faMobileAlt, faPaintBrush, faShoppingCart, faCloud, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import COLORS from '../../constants/colors'; // Replace with your defined color constants

// Updated sample data for services
const services = [
    {
      icon: faLaptopCode,
      title: 'Web Development',
      description: 'We build custom fully responsive websites for businesses, NGO\'s, schools, and all sorts of campaigns.',
    },
    {
      icon: faMobileAlt,
      title: 'Mobile App Development',
      description: 'We help you connect with your audience using cross-platform mobile applications.',
    },
    {
      icon: faPaintBrush,
      title: 'Custom Software Solutions',
      description: 'Delivering tailored software solutions to streamline your processes, solve challenges, and drive business growth.',
    },
    {
      icon: faShoppingCart,
      title: 'E-Commerce Solutions',
      description: 'Empowering businesses to thrive in the digital marketplace with robust and scalable e-commerce platforms.',
    },
    {
      icon: faCloud,
      title: 'Hosting Services',
      description: 'Building and integrating APIs for seamless functionality across systems.',
    },
    {
      icon: faLightbulb,
      title: 'Consultations',
      description: 'Expert advice to turn your ideas into successful projects and streamline your digital transformation.',
    },
  ];

function Services() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        py: 8,
        px: 0, // Remove horizontal padding
        margin: 0, // Ensure no margin is applied
      }}
    >
      

      {/* Services Grid */}
      <Grid
        container
        sx={{
          justifyContent: 'center', // Centers the items horizontally
          paddingLeft: '16px', // Add a small left padding to avoid unwanted edge contact
          paddingRight: '16px', // Add a small right padding
          maxWidth: '1500px',
          margin: '0 auto',
          overflowX: 'hidden', // Prevent horizontal scrolling
        }}
      >
        {services.map((service, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}  // This makes it 3 columns on medium and larger screens
            lg={3}  // Ensure it stays 3x2 grid on large screens
            key={index}
            sx={{
              // Apply margin for spacing between cards
              margin: { xs: '8px 0', sm: '12px' }, // Add vertical margin on small screens, horizontal margin on larger screens
            }}
          >
            {/* Service Card */}
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 2,
                borderRadius: '12px',
                boxShadow: isDarkMode
                  ? 'none'
                  : 'none',
                border: isDarkMode
                  ? `1px solid ${COLORS.border_dark}`
                  : 'none',
                backgroundColor: isDarkMode
                  ? 'transparent'
                  : 'white',
                height: '100%',
                width: '100%', // Ensure the card takes full width
                transition: 'transform 0.3s, box-shadow 0.3s',
                
              }}
            >
              {/* Icon Column */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 2,
                  ml: 2,
                  backgroundColor: isDarkMode
                    ? COLORS.primaryDark
                    : COLORS.primaryLight,
                }}
              >
                <FontAwesomeIcon
                  icon={service.icon}
                  style={{ fontSize: '40px', color: COLORS.primary }}
                />
              </Box>

              {/* Text Column */}
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: { xs: '1.1rem', md: '1.2rem' },
                    color: isDarkMode ? COLORS.white : COLORS.primary,
                    marginBottom: 1,
                  }}
                >
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: { xs: '0.8rem', md: '.9rem' },
                    color: isDarkMode
                      ? 'rgba(255, 255, 255, 0.7)'
                      : 'rgba(0, 0, 0, 0.8)',
                    lineHeight: 1.5,
                  }}
                >
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Services;
