import React from 'react';
import { Box, Grid, Typography, Divider, useTheme } from '@mui/material';
import { FaInstagram, FaWhatsapp, FaLinkedinIn, FaTiktok } from 'react-icons/fa';

import COLORS from '../../constants/colors';
import logo from '../../assets/favicon.svg'; // Import the logo
import QRCode from '../../assets/images/whatsapp.jpg';

const Footer = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: '#2C2C2C',
        color: isDarkMode ? 'rgba(255, 255, 255, 0.87)' : '#FFFFFF',
        paddingY: 4,
        pt: 6,
        paddingX: { xs: 2, sm: 4, md: 8 },
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        {/* Section 1: Company Info */}
        <Grid item xs={12} md={2.4}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '600',
              marginBottom: 1,
            }}
          >
            Delvit Solutions
            <Divider
              sx={{
                width: '40px',
                height: '3px',
                backgroundColor: COLORS.primary || '#FF7A00',
                marginTop: '8px',
              }}
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              lineHeight: 1.6,
              color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : '#CCCCCC',
            }}
          >
            We have a dedicated team that will ensure your application is developed and runs smoothly
            with maximum uptime.
          </Typography>
        </Grid>

        {/* Section 2: Quick Links */}
        <Grid item xs={12} md={2.4}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '600',
              marginBottom: 1,
            }}
          >
            Quick Links
            <Divider
              sx={{
                width: '40px',
                height: '3px',
                backgroundColor: COLORS.primary || '#FF7A00',
                marginTop: '8px',
              }}
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : '#CCCCCC',
              lineHeight: 2,
            }}
          >
            <a href="/" style={{ color: 'inherit', textDecoration: 'none' }}>Home</a>
            <br />
            <a href="/projects" style={{ color: 'inherit', textDecoration: 'none' }}>Our projects</a>
            <br />
            <a href="/services" style={{ color: 'inherit', textDecoration: 'none' }}>Our services</a>
            <br />
            <a href="/aboutus" style={{ color: 'inherit', textDecoration: 'none' }}>About us</a>
          </Typography>
        </Grid>

        {/* Section 3: Contact Details */}
        <Grid item xs={12} md={2.4}>
          <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: 1 }}>
            Contact Details
            <Divider
              sx={{
                width: '40px',
                height: '3px',
                backgroundColor: COLORS.primary || '#FF7A00',
                marginTop: '8px',
              }}
            />
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : '#CCCCCC',
              lineHeight: 2,
            }}
          >
            
            <strong>Phone:</strong> + (256) 752-627003 <br />
            <strong>Address:</strong> P.O Box 500977, Wandegeya <br />
            <strong>Working Hours:</strong> Mon–Sat: 9 AM – 6 PM (EAT).
          </Typography>
        </Grid>

        {/* Section 4: Socials */}
        <Grid item xs={12} md={2.4}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '600',
              marginBottom: 1,
            }}
          >
            Follow us on
            <Divider
              sx={{
                width: '40px',
                height: '3px',
                backgroundColor: COLORS.primary || '#FF7A00',
                marginTop: '8px',
              }}
            />
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 2,
            }}
          >
           <a href="https://www.instagram.com/delvitsolutions/profilecard/?igsh=MXZxYTg1aHh1NGF2OQ==" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={20} color={COLORS.primary || '#FF7A00'} />
          </a>
          <a href="https://wa.me/message/YWD5W5PH7OSKP1" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={20} color={COLORS.primary || '#FF7A00'} />
          </a>


          <a href="https://www.linkedin.com/company/delvit-solutions" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn size={20} color={COLORS.primary || '#FF7A00'} />
          </a>
          <a href="httpS://tiktok.com/@delvitsolutions" target="_blank" rel="noopener noreferrer">
            <FaTiktok size={20} color={COLORS.primary || '#FF7A00'} />
          </a>

          </Box>
        </Grid>

        {/* Section 5: WhatsApp QR Code */}
        <Grid item xs={12} md={2.4}>
          <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: 1 }}>
            Scan me
            <Divider
              sx={{
                width: '40px',
                height: '3px',
                backgroundColor: COLORS.primary || '#FF7A00',
                marginTop: '8px',
              }}
            />
          </Typography>
          <Box
  sx={{
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginTop: 2,
    transition: 'transform 0.3s ease', // Smooth transition for scale effect
  }}
  onMouseEnter={(e) => {
    e.currentTarget.style.transform = 'scale(1.1)'; // Increase size by 10%
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'scale(1)'; // Reset to original size
  }}
>
  <img
    src={QRCode} // Replace with your QR code file path
    alt="WhatsApp QR Code"
    style={{
      width: '150px',
      height: '150px',
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
      transition: 'transform 0.3s ease', // Smooth transition for scale effect
    }}
  />
</Box>

        </Grid>
      </Grid>

     {/* Footer Copyright */}
<Typography
  variant="body2"
  sx={{
    textAlign: 'center',
    marginTop: 6,
    color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#AAAAAA',
    fontSize: '0.875rem',
  }}
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {/* Logo */}
    <img
      src={logo} // Replace with your logo image path
      alt="Delvert Tech Logo"
      style={{
        width: '40px', // Adjust the size of the logo
        height: '30px',
        marginRight: '8px', // Space between the logo and text
      }}
    />
    {/* Copyright Text */}
    <span>Delvit Solutions {new Date().getFullYear()} All rights reserved</span>
  </Box>
</Typography>

    </Box>
  );
};

export default Footer;
