import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar,Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import COLORS from '../../constants/colors'; // Replace with your defined color constants
import image1 from '../../assets/profile_image/sinai_profile.jpg';
import image2 from '../../assets/profile_image/brian_profile.jpg';
import image3 from '../../assets/profile_image/benja_profile.jpg';
import image4 from '../../assets/profile_image/atamba_profile.jpg';



// Sample team data (replace with actual data)
const teamMembers = [
  {
    name: 'Kukiriza Sinai',
    role: 'Team Lead, AI/ML Engineer',
    imageUrl: image1, // Replace with actual image path
  },
  {
    name: 'Enzama Brian',
    role: 'Full Stack Developer',
    imageUrl: image2, // Replace with actual image path
  },
  {
    name: 'Ssemwezi Benjamin',
    role: 'Full Stack Developer',
    imageUrl: image3, // Replace with actual image path
  },
  {
    name: 'Asiimwe Atamba',
    role: 'Marketing & PR',
    imageUrl: image4, // Replace with actual image path
  },
];

function Team() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? 'background.default' : 'white',
        color: isDarkMode ? 'text.primary' : 'black',
        py: 8,
        px: 0,
        margin: 0,
      }}
    >
      {/* Section Heading */}
      {/* Section Heading */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2rem' },
          textAlign: 'center',
          marginBottom: 4,
          color: isDarkMode ? '#FFFFFF' : COLORS.primary,
        }}
      >
        Our Team
      </Typography>

      {/* Horizontal Divider */}
      <Divider
        sx={{
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : COLORS.primary, // Color based on theme
          width: '10%', // Width of the divider
          margin: { xs: '0 auto', sm: '0 auto' }, // Centers on small screens, not on larger screens
          marginBottom: { xs: 3, sm: 4 }, // Spacing below the divider
        }}
      />


      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
          marginBottom: 6,
          fontSize: { xs: '.9rem', sm: '.9rem' },
        }}
      >
        We have a dedicated team that ensures your application is developed and runs smoothly with maximum uptime.
      </Typography>

      <Grid
  container
  spacing={4}
  sx={{
    justifyContent: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
  }}
>
  {teamMembers.map((member, index) => (
    <Grid item xs={12} sm={6} md={3} key={index}>
      {/* Team Member Card */}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: 3,
          boxShadow: 'none',
          borderRadius: '16px',
          backgroundColor: isDarkMode ? '#2C2C2C' : 'white',
          height: '100%',
          width: '90%',
          overflow: 'hidden',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)', // Slight zoom effect
          }
        }}
      >
        <Avatar
          alt={member.name}
          src={member.imageUrl}
          sx={{
            width: 120,
            height: 120,
            marginBottom: 1,
            transition: 'transform 0.3s ease', // Animation for avatar on hover
            '&:hover': {
              transform: 'rotate(360deg)', // Spin effect
            },
          }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: isDarkMode ? COLORS.white : COLORS.primary,
              marginBottom: 1,
              fontSize: '1rem',
            }}
          >
            {member.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
              lineHeight: 1.5,
            }}
          >
            {member.role}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

    </Box>
  );
}

export default Team;
